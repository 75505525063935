<template>
  <div>
    <div class="container">
      <el-form ref="form" :model="queryInfo" label-width="120px">
        <el-card>
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="标签组层级:" prop="">
                <el-radio-group
                  v-model="queryInfo.groupLabelLevel"
                  @change="changeSearchangeSearch"
                >
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="1">一级标签 </el-radio-button>
                  <el-radio-button label="2">二级标签 </el-radio-button>
                  <el-radio-button label="3">三级标签 </el-radio-button>
                  <el-radio-button label="4">四级标签 </el-radio-button>
                  <el-radio-button label="5">五级标签 </el-radio-button>
                  <el-radio-button label="6">六级标签 </el-radio-button>
                  <el-radio-button label="7">七级标签 </el-radio-button>
                  <el-radio-button label="8">八级标签 </el-radio-button>
                  <el-radio-button label="9">九级标签 </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="标签组层级:" prop="">
                <el-radio-group v-model="queryInfo.groupLabelType" @change="changeSearchangeSearch">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button
                    v-for="(item, index) in grouplabel"
                    :key="index"
                    :label="item.labeltype"
                    >{{ item.labeltype }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-card>
      </el-form>
      <el-card style="margin-top: 20px">
        <div class="input-with">
          <el-button type="primary" @click="addlabel">新增</el-button>
          <el-input
            placeholder="请输入标签组名称，标签组说明"
            v-model="queryInfo.queryConditions"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="changeSearchangeSearch">搜索</el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          @sort-change="changeSort"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" prop="id"></el-table-column>
          <el-table-column label="标签名称" prop="groupLabelName" align="center"></el-table-column>
          <el-table-column
            label="标签组前缀"
            prop="groupLabelPrefix"
            align="center"
          ></el-table-column>
          <el-table-column
            label="标签组类别"
            prop="groupLabelType"
            align="center"
          ></el-table-column>

          <el-table-column label="标签组描述" align="center">
            <template v-slot="{ row }">
              <el-popover
                placement="top-start"
                title="标签组描述"
                width="200"
                trigger="hover"
                :content="row.groupLabelDescribe"
              >
                <el-button type="text" slot="reference">{{ row.groupLabelDescribe }}</el-button>
              </el-popover>
              <!-- <span>{{ row.content }}</span> -->
            </template>
          </el-table-column>

          <el-table-column label="上传人" prop="userName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="上传时间" sortable prop="createTime" align="center">
            <template v-slot="{ row }">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="更新人" prop="updateName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.updateName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" sortable prop="updateTime" align="center">
            <template v-slot="{ row }">
              <div>{{ row.updateTime | date }}</div>
              <div>{{ row.updateTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="" align="center">
            <template v-slot="{ row }">
              <span class="edit" @click="editclick(row)">编辑</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { labelPage, getGroupLabel } from '@/api/system'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  groupLabelLevel: null,
  groupLabelType: '',
  labelName: null,
  labelType: null,
  orderByParam: 'l.create_time DESC',
  pageNum: 1,
  pageSize: 10,
  queryConditions: null
})
export default {
  name: 'labelmanage',
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      grouplabel: []
    }
  },
  created() {
    // this.search()
    this.getGroupLabel()
    if (this.$route.query.pageNum) {
      this.queryInfo.pageNum = Number(this.$route.query.pageNum)
      this.search()
    } else {
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await labelPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    //查询所有标签组类别
    async getGroupLabel() {
      let list = []
      const { data: res } = await getGroupLabel()
      if (res.resultCode == 200) {
        list = res.data

        list.forEach((el) => {
          this.grouplabel.push({
            labeltype: el
          })
        })
      }
    },
    //新增标签
    addlabel() {
      this.$router.push({
        name: 'addlabel'
      })
    },
    //编辑
    editclick(row) {
      this.$router.push({
        name: 'addlabel',
        query: {
          id: row.id,
          pageNum: this.queryInfo.pageNum
        }
      })
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      console.log(val)
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'l.create_time ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'l.create_time DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'l.create_time DESC'
          this.search()
        }
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 80vh;
  overflow: scroll;
}
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}
</style>
